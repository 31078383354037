import { IconCaretDownMd } from '@troon/icons/caret-down-md';
import { twMerge } from '@troon/tailwind-preset/merge';
import type { ComponentProps, ParentProps } from 'solid-js';

export function Details(props: ComponentProps<'details'>) {
	return (
		<details {...props} class={twMerge('group text-sm', props.class)}>
			{props.children}
		</details>
	);
}

export function Summary(props: ParentProps<{ class?: string }>) {
	return (
		<summary
			class={twMerge(
				'flex cursor-pointer list-none items-center justify-between text-base outline-none focus-visible:ring-2 focus-visible:ring-brand-100 group-open:mb-4 details-marker:hidden marker:hidden',
				props.class,
			)}
		>
			{props.children}
			<span class="transition duration-300 group-open:rotate-180">
				<IconCaretDownMd />
			</span>
		</summary>
	);
}
